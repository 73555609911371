import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getApprovalspecificschema } from "../services/approvalapi";
import { Nametorender } from "../services/helper";
import { Deploymentrender } from "../services/helper";
import moment from "moment";
import ViewNewSchemaDetail from "./ViewNewSchemaDetail";

function Viewdeploymentdetail() {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    specificdata().then((specificdata) => {
      console.log(specificdata.data.data, "data");
      setPropsdata(specificdata.data.data);
      setLoading(false);
    });

    async function specificdata() {
      return await getApprovalspecificschema(id);
    }
  }, []);

  const [propsdata, setPropsdata] = useState("");

  let deploymentrender = {
    1: "Change Proposed and Change Approval Pending",
    2: "Change Approved and Pending Deployment",
    3: "Change Rejected",
    4: "Deployment Rejected",
    5: "Deployment Success",
  };

  return (
    <div>
      {!loading && (
        <div className="main_container_edit">
          <div className="program_name_container_deploymentdetail">
            {/* <h1 className='program_name' >{id}</h1> */}

            <div>
              <h4>Field Changed</h4>
              <div style={{ overflow: "hidden" }}>
                {propsdata.field_name &&
                  propsdata.field_name.map((data, idx) => {
                    return (
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "red",
                          float: "left",
                          marginTop: "0px",
                        }}
                      >
                        {data}{" "}
                        {idx == propsdata.field_name.length - 1 ? "" : ","}{" "}
                      </p>
                    );
                  })}
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h5>Deployment Status</h5>
              <p
                style={{ fontSize: "18px", fontWeight: "500", color: "green" }}
              >
                {deploymentrender[propsdata.final_status]}
              </p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h5>Deployment Environment</h5>
              <p
                style={{ fontSize: "18px", fontWeight: "500", color: "green" }}
              >
                {[
                  propsdata && propsdata.is_deployment_done.STAGING
                    ? "Staging"
                    : "",
                ]}
              </p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h5>Date and Time</h5>
              <p
                style={{ fontSize: "18px", fontWeight: "500", color: "green" }}
              >
                {moment(propsdata.updatedAt).format("DD/MM/YYYY, h:mm:ss a")}
              </p>
            </div>
          </div>
          <div style={{ marginTop: "30px" }}>
            <ViewNewSchemaDetail propsdata={propsdata} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Viewdeploymentdetail;
