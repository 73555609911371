import React from "react";
import { Link, useNavigate } from "react-router-dom";

function MainSideHeader(props) {
  return (
    <div>
      <div className="box_li_container">
        <Link to="/Home" style={{ textDecoration: "none" }}>
          <li
            onClick={(e) => props.props.handleclick(e, "")}
            className={`side_li ${props.paths == "" ? "active" : ""}`}
          >
            <p>Program Schema</p>
          </li>
        </Link>
        <Link to="/WebinarHome" style={{ textDecoration: "none" }}>
          <li
            onClick={(e) => props.handleclick(e, "WebinarHome")}
            className={`side_li ${
              props.paths == "WebinarHome" ? "active" : ""
            }`}
          >
            <p>Webinar Schema</p>
          </li>
        </Link>
        <Link to="/Webinars" style={{ textDecoration: "none" }}>
          <li
            onClick={(e) => props.handleclick(e, "Webinars")}
            className={`side_li ${props.paths == "Webinars" ? "active" : ""}`}
          >
            <p>Webinar Events</p>
          </li>
        </Link>
        <Link to="/AuxiliaryHome" style={{ textDecoration: "none" }}>
          <li
            onClick={(e) => props.handleclick(e, "AuxiliaryHome")}
            className={`side_li ${
              props.paths == "AuxiliaryHome" ? "active" : ""
            }`}
          >
            <p>Auxiliary Schema</p>
          </li>
        </Link>
        <Link to="/LocationHome" style={{ textDecoration: "none" }}>
          <li
            onClick={(e) => props.handleclick(e, "LocationHome")}
            className={`side_li ${
              props.paths == "LocationHome" ? "active" : ""
            }`}
          >
            <p>Location Schema</p>
          </li>
        </Link>
        <Link to="/Approvalwindow" style={{ textDecoration: "none" }}>
          <li
            onClick={(e) => props.handleclick(e, "Approvalwindow")}
            className={`side_li ${
              props.paths == "Approvalwindow" ? "active" : ""
            }`}
          >
            <p>Approvals</p>
          </li>
        </Link>
        <Link to="/Deployment" style={{ textDecoration: "none" }}>
          <li
            onClick={(e) => props.handleclick(e, "Deployment")}
            className={`side_li ${props.paths == "Deployment" ? "active" : ""}`}
          >
            <p>Deployment</p>
          </li>
        </Link>

        {/* <Link to='/Settings' style={{textDecoration: 'none'}}>
              <li onClick={e => (props.handleclick(e, "Settings")) } className={`side_li ${props.paths=="Settings"? "active" : ""}`} >
                  <p>Settings</p>
              </li>
            </Link> */}
      </div>
    </div>
  );
}

export default MainSideHeader;
