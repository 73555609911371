import React from "react";
import Home from "./Home";
import Allcourses from "./Allcourses";
import Newcourseentry from "./Newcourseentry";
import Viewcoursedetail from "./Viewcoursedetail";
import { Navigate, Route, Routes } from "react-router-dom";
import Editcourse from "./Editcourse";
import Approval from "./Approvalwindow";
import Approvalspecific from "./Approvalspecific";
import Deployment from "./Deployment";
import Deploymentspecific from "./Deploymentspecific";
import Viewdeploymentdetail from "./Viewdeploymentdetail";
import WebinarHome from "./WebinarHome";
import WebinarEdit from "./WebinarEdit";
import Webinardetails from "./Webinardetails";
import Viewapprovaldetail from "./Viewapprovaldetail";
import Viewprogramjson from "./Viewprogramjson";
import AuxiliaryHome from "./AuxiliaryHome";
import Viewseodetail from "./Viewseodetail";
import Settings from "./Settings";
import Newsview from "./Newsview";
import Mediauploader from "./Mediauploader";
import ImageUploader from "./ImageUploader";
import Newsspecific from "./Newsspecific";
import Editnews from "./Editnews";
import Editauxpage from "./Editauxpage";
import Createauxpage from "./Createauxpage";
import Locationdetail from "./Locationdetail";
import LocationEdit from "./LocationEdit";
import LocationHome from "./LocationHome";
import CreateLocation from "./CreateLocation";
import Lead from "./Lead";
import Programlocation from "./Programlocation";
import ProgramlocationEdit from "./ProgramlocationEdit";
import ViewLocatonProgramJson from "./ViewLocationProgramJson";
import UtmHome from "./UtmHome";
import UtmDetail from "./UtmDetail";
import UtmEdit from "./UtmEdit";
import CreateUtms from "./CreateUtms";
import Viewprogramlocationpages from "./Viewprogramlocationpages";
import CapiDashboard from "./CapiDashboard";
import HaptikProgramNameAutomation from "./HaptikProgramNameAutomation";
import CreateLead from "./CreateLead";
import AddProgram from "./AddProgram";
import TemplatePageCreation from "./TemplatePageCreation";
import FbLeadDashboard from "./FbLeadDashboard";
import LeadDistributionPanel from "./LeadDistributionPanel";
import ViewAdvisor from "./LeadDistribution/LeadDistributionPanelAdvisors";
import Webinar from "./Webinars";
import AddWebinar from "./AddWebinar";
import ViewWebinarDetails from "./ViewWebinarDetails";
import GoogleCapiDashboard from "./GoogleCapiDashboard";
import WebinarCampaign from "./WebinarCampaign";
import LeadDistributionTarget from "./LeadDistributionTarget";
import UnProcessedFbLead from "./UnProcessedFbLead";
import ViewPageContent from "./ViewPageContent";

function Componentrender(props) {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/allcourses" element={<Allcourses />} />
        <Route path="/Newcourseentry" element={<Newcourseentry />} />
        <Route path="/Viewcoursedetail:id" element={<Viewcoursedetail />} />
        <Route path="/Editcourse:id" element={<Editcourse />} />
        <Route path="/WebinarHome" element={<WebinarHome />} />
        <Route path="/Settings" element={<Settings />} />
        <Route path="/Webinardetails:id" element={<Webinardetails />} />
        <Route path="/WebinarEdit:id" element={<WebinarEdit />} />
        <Route path="/Approvalwindow" element={<Approval />} />
        <Route path="/Approvalspecific:id" element={<Approvalspecific />} />
        <Route path="/Viewapprovaldetail:id" element={<Viewapprovaldetail />} />
        <Route path="/Deployment" element={<Deployment />} />
        <Route path="/Deploymentspecific:id" element={<Deploymentspecific />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route
          path="/Viewdeploymentdetail:id"
          element={<Viewdeploymentdetail />}
        />
        <Route path="/Newsview" element={<Newsview />} />
        <Route path="/Mediauploader" element={<Mediauploader />} />
        <Route path="/ImageUploader:id" element={<ImageUploader />} />
        <Route path="/Newsspecific:id" element={<Newsspecific />} />
        <Route path="/Editnews:id" element={<Editnews />} />
        <Route path="/Viewprogramjson:id" element={<Viewprogramjson />} />
        <Route path="/AuxiliaryHome" element={<AuxiliaryHome />} />
        <Route path="/Viewseodetail:id" element={<Viewseodetail />} />
        <Route path="/Editauxpage:id" element={<Editauxpage />} />
        <Route path="/Createauxpage" element={<Createauxpage />} />
        <Route path="/LocationDetail:id" element={<Locationdetail />} />
        <Route path="/LocationEdit:id" element={<LocationEdit />} />
        <Route path="/LocationHome" element={<LocationHome />} />
        <Route path="/CreateLocation" element={<CreateLocation />} />
        <Route
          path="/ProgramlocationEdit:id"
          element={<ProgramlocationEdit />}
        />
        <Route path="/ProgramLocation:id" element={<Programlocation />} />
        <Route path="/Lead" element={<Lead />} />
        <Route path="/UtmHome" element={<UtmHome />} />
        <Route path="/UtmDetail:id" element={<UtmDetail />} />
        <Route path="/UtmEdit:id" element={<UtmEdit />} />
        <Route path="/CreateUtms" element={<CreateUtms />} />
        <Route
          path="/Viewlocatonprogramjson:id"
          element={<ViewLocatonProgramJson />}
        />
        <Route
          path="/Viewprogramlocationpages"
          element={<Viewprogramlocationpages />}
        />
        <Route path="/capidashboard" element={<CapiDashboard />} />
        <Route path="/googlecapidashboard" element={<GoogleCapiDashboard />} />
        <Route
          path="/HaptikProgramNameAutomation"
          element={<HaptikProgramNameAutomation />}
        />
        <Route path="/CreateLead" element={<CreateLead />} />
        <Route path="/AddProgram" element={<AddProgram />} />
        <Route
          path="/TemplatePageCreation:id"
          element={<TemplatePageCreation />}
        />
        <Route path="/FbLeadDashboard" element={<FbLeadDashboard />} />
        <Route
          path="/LeadDistributionPanel"
          element={
            <LeadDistributionPanel
              props={props}
              leadDistubtionData={props.leadDistubtionData}
              resetLeadDistributionFilter={props.resetLeadDistributionFilter}
            />
          }
        />
        <Route
          path="/WebinarCampaign"
          element={<WebinarCampaign props={props} />}
        />
        <Route
          path="/LeadDistributionPanelAdvisors"
          element={<ViewAdvisor props={props} />}
        />
        <Route path="/CreateUtms" element={<CreateUtms />} />
        <Route path="/Webinars" element={<Webinar />} />
        <Route path="/AddWebinar" element={<AddWebinar />} />
        <Route path="/EditWebinar:id" element={<ViewWebinarDetails />} />
        <Route
          path="/LeadDistributionTarget"
          element={<LeadDistributionTarget />}
        />
        <Route path="/UnProcessedFbLead" element={<UnProcessedFbLead />} />
        <Route
          path="/ViewPageContent:id"
          element={<ViewPageContent props={props} />}
        />
      </Routes>
    </>
  );
}

export default Componentrender;
