import React, { useState, useEffect } from "react";
import "./css/leaddistribution.css";
import { Link, useNavigate } from "react-router-dom";
import {
  getSalesGroup,
  getRevenuePrograms,
  getPgLsKeyAndCost,
} from "../services/leaddistribution";
import { getParameterByName } from "../services/helper";

function Leadsideheader(props) {
  const navigate = useNavigate();
  const [salesGroup, setSalesGroup] = useState("");
  const [program, setProgram] = useState("");
  const [loading, setLoading] = useState();
  const [dropdownProgram, setDropdownPrograms] = useState("");

  const [dropdownData, setDropdownData] = useState("");
  useEffect(() => {
    let program = decodeURIComponent(getParameterByName("program"));
    console.log({ program });
    let salesGroup = getParameterByName("salesGroup");

    setProgram(program);
    setSalesGroup(salesGroup);

    getSalesGroupName().then((data) => {
      console.log(data);
      setDropdownData(data.data);
      props.leadData &&
        props.leadData({
          salesGroupArray: data.data,
          salesGroup: salesGroup,
          program: program,
        });
      setLoading(false);
    });

    async function getSalesGroupName() {
      setLoading(true);
      return await getSalesGroup();
    }
  }, []);

  useEffect(() => {
    getRevenueProgram().then((data) => {
      console.log(data);
      setDropdownPrograms(data.data);
    });

    async function getRevenueProgram() {
      return await getPgLsKeyAndCost(salesGroup);
    }
  }, [salesGroup]);

  useEffect(() => {
    props.leadData &&
      props.leadData({
        salesGroupArray: dropdownData,
        salesGroup: salesGroup,
        program: program,
      });
  }, [salesGroup, program]);

  useEffect(() => {
    if (props && props.resetLeadFilter) {
      setProgram("");
      setSalesGroup("");
    }
  }, [props.resetLeadFilter]);

  return (
    <div>
      <div className="box_li_container">
        <Link to="/Home" style={{ textDecoration: "none" }}>
          <p className="sideheaderleadbackbtn">Back</p>
        </Link>

        <div className="sideheaderleadmain">
          <Link
            to="/LeadDistributionPanel"
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <li
              className={`side_li ${
                props.paths.includes("LeadDistributionPanel") ? "active" : ""
              }`}
              onClick={(e) => props.handleclick(e, "LeadDistributionPanel")}
              style={{ paddingLeft: "18px" }}
            >
              <p>Lead Distribution Panel</p>
            </li>
          </Link>

          <div className="sideheaderleaddropdowncontainer">
            <select
              value={salesGroup}
              onChange={(e) => setSalesGroup(e.target.value)}
              className="sideheaderleaddropdown"
              name="sidesalesgroup"
              id="sidesalesgroup"
            >
              <option
                selected
                disabled
                value=""
                style={{ color: "black", background: "#ffff" }}
              >
                Sales Group
              </option>
              <option value="" style={{ color: "black", background: "#ffff" }}>
                All Sales Group
              </option>
              {dropdownData &&
                dropdownData.map((data) => {
                  return (
                    <option
                      value={data._id}
                      style={{ color: "black", background: "#ffff" }}
                    >
                      {data.email}
                    </option>
                  );
                })}
            </select>
            <select
              value={program}
              onChange={(e) => {
                console.log(e.target.value, "program");
                setProgram(e.target.value);
              }}
              className="sideheaderleaddropdown"
              name="sideprogram"
              id="sideprogram"
              // disabled={salesGroup == "" ? true : false}
            >
              <option selected disabled value="">
                Program
              </option>
              <option value="" style={{ color: "black", background: "#ffff" }}>
                All Program
              </option>
              {dropdownProgram &&
                dropdownProgram.map((data) => {
                  return (
                    <option
                      style={{ color: "black", background: "#ffff" }}
                      value={`${data.leadsquareProgramKey}`}
                    >
                      {data.leadsquareProgramKey}
                    </option>
                  );
                })}
            </select>
          </div>

          <Link
            to="/WebinarCampaign"
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            <li
              style={{ paddingLeft: "18px" }}
              className={`side_li ${
                props.paths.includes("WebinarCampaign") ? "active" : ""
              }`}
              onClick={(e) => props.handleclick(e, "WebinarCampaign")}
            >
              <p>Webinar Campaign</p>
            </li>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Leadsideheader;
