import axios from "axios";
import { configs } from "../constants";
import Cookies from "universal-cookie";

export async function addSalesGroup(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/add_sales_group`,
      { data, aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function addSubSalesGroup(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/add_sub_sales_group`,
      { data, aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function getSalesGroup() {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_sales_group`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getSubSalesGroup(data) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_sub_sales_group?salesGroup=${data}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getRevenuePrograms(data) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_revenue_programs?salesGroup=${data}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getAdvisorsList() {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_advisors_list`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function assignSalesAndSubSalesGroup(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/assign_sales_sub_sales_group`,
      { data, aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function getPgLsKeyAndCost(salesGroup) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_pg_lskey_cost?salesGroup=${salesGroup}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function addProgramCost(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/add_pg_cost`,
      { data, aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function addAdmissionTarget(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/add_admission_target`,
      { data, aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function getPls() {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_pls`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getLeads(
  fromDate,
  toDate,
  salesGroup,
  program,
  leadSource,
  subSalesGroup,
  currentPage,
  PER_PAGE,
  isFirst
) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    const res = await axios.get(
      `${
        configs.MARKETING_ENDPOINT
      }/marketingopspanel/get_leads?fromData=${fromDate}&toData=${toDate}&salesGroup=${salesGroup}&program=${encodeURIComponent(
        program
      )}&leadSource=${leadSource}&subSalesGroup=${subSalesGroup}&pageNumber=${currentPage}&limit=${PER_PAGE}&isFirst=${isFirst}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getAggregateData(fromDate, toDate, isFirst) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/aggrgate_analysis?fromDate=${fromDate}&toDate=${toDate}&isFirst=${isFirst}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function exportLeadsToCsv(
  fromDate,
  toDate,
  salesGroup,
  program,
  leadSource,
  subSalesGroup,
  limit
) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/export_leads?fromData=${fromDate}&toData=${toDate}&salesGroup=${salesGroup}&program=${program}&leadSource=${leadSource}&subSalesGroup=${subSalesGroup}&limit=${limit}`,
      {
        responseType: "blob",
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function rmSalesGroupAndSubSalesGroup(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/rm_salesGroup_and_subSalesGroup`,
      { data, aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function createWebinarLeadsource(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  data.aid = aid;
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/create_webinar_campaign_leadsource`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function getWebinarLeadSource() {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/webinar_leadsource`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getLeadByPhone(phone) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_lead_by_phone?phone=${phone}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return error;
  }
}

export async function getTagets(date, program) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/gt_tgt?date=${date}&pg=${program}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return error;
  }
}

export async function getUnProcessedFbLead(currentPage, PER_PAGE) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/gt_unporcess_fb_raw_lead?pageNumber=${currentPage}&limit=${PER_PAGE}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return error;
  }
}
