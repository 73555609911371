import React, { useState, useEffect, useRef, createRef } from "react";
import { useParams } from "react-router-dom";
import {
  getTemplate,
  updatePageTemplate,
  approvePageTemplate,
  getProgramTemplate,
  previewTemplatePage,
} from "../services/templateapi";
import Loader from "./loader/loading";
import { uploadImages } from "../services/newsapi";
import {
  getApprovalspecificschema,
  getOtprequest,
} from "../services/approvalapi";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";

import RJSFSchema from "@rjsf/core";
import TemplateUrlModel from "./TemplateUrlModel";
import { configs } from "../constants";

function TemplatePageCreation() {
  const FormRef = useRef(null);
  let id = useParams();
  const [tId, setTId] = useState("");
  const [templateData, setTemplateData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [formDataJson, setFormDataJson] = useState({});
  const [finalSchema, setFinalSchema] = useState({});
  const [showFrom, setShowForm] = useState(false);
  const [linkButton, setLinkButton] = useState(false);
  const [resLink, setResLink] = useState("");
  const [jsonData, setJsonData] = useState({});

  useEffect(() => {
    setTId(id.id);
    getTemplatedata().then((data) => {
      setTemplateData(data.data);

      getFormData().then((data) => {
        setFormDataJson(data);
        setShowForm(true);
        setLoading(false);
      });

      async function getFormData() {
        setLoading(true);
        let json = "";
        if (data.data.finalOutputJson) {
          console.log(true);
          let d = data.data.finalOutputJson;
          let obj = {};
          let programAttributes = {};
          for (let i in d) {
            if (typeof d[i] != "object") {
              programAttributes[i] = d[i];
            } else {
              obj[i] = { ...d[i] };
            }
          }
          obj.programSchema = { ...obj.programSchema, programAttributes };
          console.log("in getform data 1");
          return (json = obj.programSchema);
        } else {
          console.log("in getform data 2");
          json = data.data.schemaName.ProgramData;
          return json;
        }
      }

      setLoading(false);
      // if (data.data.finalOutputJson) {
      //   setShowBtn(true);
      //   setFinalSchema(data.data.finalOutputJson);
      // }
    });

    async function getTemplatedata() {
      setLoading(true);
      return await getTemplate(id.id);
    }
  }, []);

  const handleSubmit = async (e) => {
    console.log(e.formData);
    let data = e.formData;
    let programData = {};
    setLoading(true);
    programData = data.programAttributes;
    programData.link = templateData.link;
    programData.leadsquareProgramKey = templateData.leadsquareProgramKey;
    delete data.programAttributes;
    programData.programSchema = data;
    console.log(programData);
    setFinalSchema(programData);
    let res = await updatePageTemplate(templateData._id, programData);
    console.log(res);
    if (res.status == 200) {
      alert(res.data.data.msg);
      setLoading(false);
      setShowBtn(true);
    }
    setLoading(false);
  };

  const handlePreview = async (e) => {
    setLoading(true);
    let url = templateData.link;
    let templateName = templateData.schemaName.schemaName;
    let data = await previewTemplatePage(url, templateName);
    console.log(data);
    if (data.status == 200) {
      setResLink(data.data.data);
      setLinkButton(true);
      setLoading(false);
    } else {
      alert("Couldn't start preview please connect with the developer");
      setLoading(false);
    }
  };

  const handleSubmitApproval = async (e) => {
    setLoading(true);
    let approvalRes = await approvePageTemplate(templateData._id, finalSchema);
    console.log(approvalRes);
    if (approvalRes.status !== 200) {
      alert("Fill the detail correctly");
      setLoading(false);
      setShowBtn(false);
      return;
    }
    createOtpReques().then((data) => {
      console.log({ data });
      getOtprequest(
        {
          Fieldchange: data.data.data.field_name,
          Newdata: data.data.data.new_schema,
          Olddata: data.data.data.old_schema,
        },
        data.data.data._id
      );

      alert("Successfully Added");
      setLoading(false);
      window.open("/Approvalwindow", "_self");
    });

    async function createOtpReques() {
      return await getApprovalspecificschema(approvalRes.data.data);
    }
  };

  useEffect(() => {
    setJsonData(formDataJson);
  }, [formDataJson]);

  const updateFormJson = (id, file) => {
    console.log({ formDataJson });
    console.log(id, file);
    let key = id.split("_");
    console.log(key[1], key[2]);
    let updateData = { ...formDataJson };
    updateData[key[1]][key[2]] = file;
    console.log({ updateData });
    setFormDataJson(updateData);
  };

  const FileUpload = (props) => {
    const { value, onChange } = props;
    const [file, setFile] = useState();
    const handleChange = async (e) => {
      console.log({ jsonData });
      const formdata = new FormData();
      formdata.append("file", e.target.files[0]);
      setLoading(true);
      let id = templateData.schemaName.schemaName.replace(/\s/g, "");
      const res = await uploadImages(
        templateData.leadsquareProgramKey,
        formdata
      );
      if (res.success === true) {
        setFile(res.data);
        props.onChange(res.data);
        // updateFormJson(props.id, res.data);
        alert("Image Uploaded ");
        setLoading(false);
      } else {
        alert("Image Size Exceeded, It should be under 80KB");
        setLoading(false);
      }
    };
    return (
      <div>
        <p>
          <input
            type={"file"}
            // accept={allowedInput}
            onChange={handleChange}
          ></input>
        </p>
        {file
          ? ""
          : value && (
              <div className="fileName">
                <a href={value} target="_blank" className="fileName">
                  {new URL(value).pathname.replace("/", "")}
                </a>
              </div>
            )}
        {file && (
          <a href={file} target="_blank" className="fileName">
            {new URL(file).pathname.replace("/", "")}
          </a>
        )}
        {value && (
          <button
            className="fileRemove"
            onClick={() => {
              console.log("inside remove");
              onChange(undefined);
            }}
          >
            Remove
          </button>
        )}
      </div>
    );
  };

  const widgets = {
    FileWidget: FileUpload,
  };

  const transformErrors = (errors) => {
    console.log(errors);
    const uiSchema = FormRef.current.state.uiSchema;
    let duplicateError = [];

    return errors
      .filter((error) => {
        let schema =
          uiSchema[error.property.slice(1)] ||
          uiSchema[error.property.slice(0)];
        let toDisplay = true;
        if (
          schema &&
          schema["ui:widget"] == "file-upload" &&
          error.name == "type" &&
          error.params.type == "string"
        ) {
          toDisplay = false;
        }
        if (error.name == "anyOf" || error.name == "if") {
          toDisplay = false;
        }

        return toDisplay;
      })
      .map((error) => {
        if (error.property && error.schemaPath) {
          return " ";
        }

        return error;
      });
  };

  const handleClose = () => setLinkButton(false);

  const handleStateUpdate = (e) => {
    setFormDataJson(e.formData);
  };

  return (
    <>
      {loading && <Loader />}
      {linkButton && (
        <TemplateUrlModel
          show={linkButton}
          handleClose={handleClose}
          url={configs.DEV_PORT_LINK + resLink}
        />
      )}
      <div style={{ marginLeft: "5%" }}>
        <h5
          style={{
            fontSize: "16px",
            opacity: "0.85",
            color: "#3c4852",
            marginBottom: "16px",
          }}
        >
          Selected Template
        </h5>
        <p
          style={{
            color: "#3c4852",
            fontSize: "20px",
            lineHeight: "28px",
            fontWeight: "400",
            paddingBottom: "16px",
            borderBottom: "1px solid rgba(60, 72, 82, 0.25)",
          }}
        >
          {templateData &&
            templateData.schemaName &&
            templateData.schemaName.schemaName}
        </p>

        <div style={{ marginTop: "24px", marginBottom: "40px" }}>
          {templateData.schemaName && formDataJson && showFrom && (
            <>
              <Form
                className="template-create-page"
                schema={templateData.schemaName.FormDataSchema}
                validator={validator}
                uiSchema={templateData.schemaName.Edituischema}
                formData={formDataJson}
                widgets={widgets}
                ref={FormRef}
                transformErrors={transformErrors}
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                onChange={(e) => {
                  handleStateUpdate(e);
                }}
              />
            </>
          )}

          <div className="template-btn-div">
            {showBtn && (
              <div className="template-btns">
                <button
                  onClick={handlePreview}
                  className="template-btn-preview"
                >
                  Preview
                </button>
                <button
                  onClick={handleSubmitApproval}
                  className="template-btn-approval"
                >
                  Submit for Approval
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TemplatePageCreation;
